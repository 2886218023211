<template>
  <div>
    <leaflet-panel
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
      :mark="mark"
    />
    <!-- <ImageList :images="images"></ImageList> -->

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`产品介绍`"
      :en_title="`Product Description`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist1"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`行业背景和业务痛点`"
      :en_title="`Industry Background & Business Pain Points`"
    ></Paragraph>
    <Titlenewlinelist
      :datalist="datalist2"
      style="margin-bottom: 6rem"
    ></Titlenewlinelist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`技术优势`"
      :en_title="`Technical Advantages`"
    ></Paragraph>
    <Logotitlebox :datalist="datalist3"></Logotitlebox>
    <Logotitlebox :datalist="datalist4"></Logotitlebox>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
      :title="`应用场景与服务内容`"
      :en_title="`Application Scenarios & Service Content`"
    ></Paragraph>

    <div
      style="
        display: flex;
        flex-direction: row;
        margin-left: 10%;
        margin-right: 10%;
      "
    >
      <Lettertitleblock
        :datalist="datalistl"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          width: 100%;
        "
      ></Lettertitleblock>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Titlenewlinelist from '@/components/chain/basic/common/titlenewlinelist';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Titlestrlist,
    Titlenewlinelist,
    Logotitlebox,
    Lettertitleblock,
  },
  data() {
    return {
      title: '上证链服务',
      body:
        '上证链是由证所信息网络有限公司提供的云链一体化的基础技术设施，为行业的科技驱动业务创新提供基础支撑。'
        + '是以本所为核心机构，联合监管机构、司法机构或公证机构、证券公司等共建的“证券业可信联盟”。',
      image: require('@/assets/image/chain/alliance/ssebs/ssebs.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/alliance/ssebs/20_01.jpg'),
        require('@/assets/image/chain/alliance/ssebs/20_02.jpg'),
        require('@/assets/image/chain/alliance/ssebs/20_03.jpg'),
        require('@/assets/image/chain/alliance/ssebs/20_04.jpg'),
      ],
      mark: '1',
      datalist1: [
        {
          title: '> ',
          content:
            '围绕“可信、可监管、安全、自主”，以上证云为基础设施，构建证券行业专属区块链基础功能平台，打造云链一体化。',
        },
        {
          title: '> ',
          content:
            '贯彻“区块链即服务”理念，为证券行业开展相关业务提供基础技术支撑和辅助工具服务。',
        },
        {
          title: '> ',
          content:
            '组织行业客户、科研机构、业务解决方案提供商等多方参与，形成专注于行业应用场景的合作体系。',
        },
      ],
      datalist2: [
        {
          title: '>  1）履行监管要求留痕举证难',
          content:
            '根据《证券期货投资者适当性管理办法》第19条规定：金融机构应该应当就产品或者服务风险高于投资者承受能力进行特别的书面风险警示。第25条规定：金融机构应有完善配套留痕，由投资者以电子方式确认；第32条规定，揭示资料要有较长时间的保存，法律规定不得少于20年；第34条规定，与普通投资者发生纠纷时，金融机构应提供已向投资者履行相应义务的证明；另外，《证券金融机构投资指引》中提到，证券公司在向客户提供服务时，应由投资者签字确认。',
        },
        {
          title: '> 2）购买产品过程繁琐，短信认证延时，客户体验差',
          content:
            '在实际理财业务中，公募、私募与资管产品的购买过程繁琐，短信验证环节时有延迟，造成客户体验差。不利于业务拓展，影响销售业务需求。',
        },
        {
          title: '> 3）客户关键行为举证难',
          content:
            '跟客户发生纠纷时，证券公司要提供证据，证明履行了告知义务。然而，所有相关的留痕都只在证券公司的资料库里，对客户而言，公信力还是不够。因此，就需要依赖第三方存证机构提供的单点证据。',
        },
        {
          title: '> 4）数据容易被篡改',
          content:
            '这是行业普遍存在的问题。区块链作为新兴的技术手段，可以解决数据安全性差的问题。区块链的存证集成可以实现全网同步，数据不被篡改，留痕信息不可抵赖，而且数据可加密，抗攻击的能力压力较强。',
        },
      ],
      datalist3: [
        {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '云上服务',
          list: [
            '依托基础资源强大的“上证云“，',
            '全国部署节点覆盖',
            '证券行业主要参与主体',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '合约管理',
          list: [
            '未来支持智能合约集成开发环境',
            '提供合约在线编辑和调试',
            '支持合约在线审计与审查',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/car.svg'),
          title: '灵活部署',
          list: [
            '支持云上部署或私有化部署方式',
            '可根据用户场景需求',
            '选择合适的部署方案',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/king.svg'),
          title: 'CA认证',
          list: [
            '支持使用国密算法签发用户所需的',
            '公私钥，未来支持链上可信身份与',
            'CA中心的绑定',
            '&nbsp;',
            '&nbsp;',
          ],
        },
      ],
      datalist4: [
        {
          imageurl: require('@/assets/image/contentsvg/house.svg'),
          title: '隐私保护',
          list: [
            '采用多链隔离方式，不仅在逻辑上隔离',
            '甚至可以在物理资源上相互独立',
            '客户可以放心对接自有信息系统',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '动态扩展',
          list: [
            '用户可以根据需求',
            '动态申请发行子链，应用、合约资源',
            '灵活设置节点配置',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '动态扩展',
          list: [
            '提供多种数据存储方案',
            '应对不同场景对存储系统的',
            '不同要求',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '动态扩展',
          list: [
            '制定并遵从联合治理的解决方案',
            '包括节点权限、准入投票、',
            '争议仲裁的机制',
          ],
        },
      ],
      datalistl: [
        {
          title: '场景',
          imgsrc: 'A',
          list: [
            '为证券行业提供区块链技术基础设施，',
            '包括运行所需的软硬件环境、底层链日常',
            '运营管理、核心技术的升级迭代',
          ],
        },
        {
          title: '场景',
          imgsrc: 'B',
          list: [
            '为使用行业区块链的机构提供',
            '许可接入规范辅导、技术接口、开发培训和',
            '部署支持，组织技术厂商建设业务场景',
            '开展相应的服务',
          ],
        },
        {
          title: '场景',
          imgsrc: 'C',
          list: [
            '开发和完善监管所需的辅助设施和工具',
            '为应用行业区块链的机构提供合规支持',
            '为监管机构提供监控服务',
          ],
        },
        {
          title: '场景',
          imgsrc: 'D',
          list: ['遵循商业原则进行运作和服务'],
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
